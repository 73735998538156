import { Modal } from '../Modal/Modal';
import { SignIn } from '../../../modules/sign-in/SignIn';
import { useRouter } from '../../../lib/hooks/use-router';
import { useAppContext } from '../../../context/AppProvider/AppProvider';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';

type AuthModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

export const AuthModal = ({ isOpen, onClose }: AuthModalProps) => {
  const auth = getAuth();
  const isMobile = useIsMobile();
  const { user } = useAppContext();
  const { push, query } = useRouter();

  const editModal = query.editModal === 'true';

  useEffect(() => {
    if (isOpen && !editModal && user?.isFullyRegistered && auth.currentUser) {
      push('/account/profile').catch(console.error);
      onClose();
    }
  }, [isOpen, editModal, user?.isFullyRegistered, auth.currentUser]);

  return (
    <Modal {...{ isOpen, onClose }} isFullScreen={isMobile}>
      <SignIn isPromo/>
    </Modal>
  );
};