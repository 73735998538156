import styled from 'styled-components';
import { Button } from '../../../components/UI/Button/Button';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div`
  position: relative;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #fff5e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledInnerWrapper = styled.div`
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${desktopMedia}) {
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    justify-content: start;
    align-items: start;
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  position: relative;
  z-index: 2;

  @media (${desktopMedia}) {
    justify-content: start;
    align-items: start;
    text-align: left;
    width: 50%;
    gap: 30px;
  }
`;

export const StyledLogo = styled.div`
  position: relative;
  width: 70dvw;
  height: 100px;

  @media (${desktopMedia}) {
    width: 180px;
    height: 40px;
  }
`;

export const StyledMainText = styled.div`
  font-family: 'Poppins', serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1.2;

  @media (${desktopMedia}) {
    font-size: 38px;
  }
`;

export const StyledMainTextBold = styled.div`
  font-family: 'Poppins', serif;
  font-weight: 800;
  font-style: normal;
  font-size: 28px;
  line-height: 1.2;

  @media (${desktopMedia}) {
    font-size: 40px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #e51539 !important;
  width: 240px;
  color: white !important;
  border-radius: 10px !important;
  font-family: 'Poppins', serif;
  font-weight: 800;
  font-style: normal;
  font-size: 20px;

  -webkit-appearance: none; /* Safari-specific adjustment */
  -webkit-border-radius: 10px; /* Ensures Safari applies border-radius */

  @media (${desktopMedia}) {

  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (${desktopMedia}) {

  }
`;

export const StyledInfoTitle = styled.div`
  font-family: 'Poppins', serif;
  font-weight: 800;
  font-style: normal;
  font-size: 20px;

  @media (${desktopMedia}) {
    font-size: 24px;
  }
`;

export const StyledInfoItem = styled.a`
  font-family: 'Poppins', serif;
  
  p {
    font-family: 'Poppins', serif;
  }

  @media (${desktopMedia}) {
    font-size: 20px;
  }
`;

export const StyledImageWrapper = styled.div`
  display: none;

  @media (${desktopMedia}) {
    display: block;
    position: absolute;
    bottom: 40%;
    right: 27%;
    transform: translate(50%, 50%);
    z-index: 2;
  }

`;

export const StyledImage = styled.div`
  @media (${desktopMedia}) {
    position: relative;
    width: 600px;
    height: 600px;
  }
`;

export const StyledImageText = styled.div`

  @media (${desktopMedia}) {
    font-family: 'Roboto Condensed', serif;
    position: relative;
    right: -100px;
    bottom: 90px;
    font-weight: 800;
  }

`;